/* eslint-disable */
import * as shared from '../shared';

googletag.cmd.push(function () {
// hospital_top
  googletag.defineSlot(
    '/21678620621/medicalnote_sp_hospital_top_20181003',
    shared.spTopSizes(),
    'div-gpt-ad-1538560084102-0'
  ).addService(googletag.pubads());

  // hospital_bottom
  googletag.defineSlot(
    '/21678620621/medicalnote_sp_hospital_bottom_20181003',
    shared.spBottomSizes(),
    'div-gpt-ad-1538560141379-0'
  ).defineSizeMapping(shared.setDfpResponsiveSize()).addService(googletag.pubads());

  // hospital_middle
  googletag.defineSlot(
    '/21678620621/medicalnote_sp_hospital_inside_house_20180912',
    [[320, 100], [320, 50], [320, 160], [336, 280], [300, 250], [300, 310], [336, 340]],
    'div-gpt-ad-1536722990654-0'
  ).addService(googletag.pubads());

  shared.setDiseaseKeyValue();
  shared.setSymptomKeyValue();
  shared.setContentTypeKeyValue();
  shared.setHospitalDepartmentKeyValue();
  shared.setEnvKeyValue();
  shared.setAdTagKeyValue();
  shared.setPathKeyValue();
  shared.setDoctorKeyValue();

  if (!shared.isMobile()) googletag.pubads().enableSingleRequest();
  googletag.pubads().collapseEmptyDivs();
  googletag.enableServices();
});
/* eslint-enable */
